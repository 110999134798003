.Sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 60px;
  z-index: 12;
}

.Sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
}

.Sidebar ul li {
  color: hsla(240,3%,30%,1);
  margin: 35px 0;
  padding: 0 35px;
  font-size: 20px;
}

.Sidebar ul li a {
  text-decoration: none;
  color: hsla(240,3%,30%,1);
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}

.Sidebar ul li a:hover {
  color: hsla(240,3%,60%,1);
}
