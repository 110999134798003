/* Default (>1920px) */

.banner {
    flex: 1 1 auto;
    width: calc(100vw - 100px);
    height: 80vh;
    display: flex;
    align-items: center;
}

main .banner .banner-text h5 {
    font-family: 'Libre Baskerville', 'Arial', sans-serif;
    font-size: 1.75em;
    color: hsla(240, 3%, 65%, 1);
    font-weight: 400;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    text-overflow: hidden;
    margin-left: 200px;
    line-height: 20px;
}

main .banner .banner-text .banner-title {
    margin-left: 200px;
    white-space: normal;
    word-wrap: break-word;
}

main .banner .banner-text .banner-title h1 {
    display: inline-block;
    font-family: 'Libre Baskerville', 'Arial', sans-serif;
    font-size: 6.2em;
    color: hsla(240, 3%, 98%, 1);
    font-weight: 600;
    text-shadow: 4px 4px 15px rgba(0, 0, 0, 0.6);
    line-height: 110px;
    margin-top: -20px;
    letter-spacing: 1px;
    white-space: nowrap;
    word-wrap: break-word;
    position: relative;
    top: 0;
    left: 0;
    z-index: 11;
}

main .banner .banner-text .banner-description {
    margin-left: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 260px);
    margin-top: 0px;
    background: hsla(240, 3%, 12%, 0.75);
    border-radius: 16px;
    box-shadow: 6px 6px 10px hsla(240, 3%, 3%, 0.2), 0 0 20px hsla(240, 3%, 8%, 0.1);
    padding: 45px 30px 30px 18px;
    transform: translateY(0px);
    height: 160px;
}

main .banner .banner-text .banner-description .banner-icons {
    flex: 0 0 35%;
    height: auto;
}

main .banner .banner-text .banner-description .banner-description-text {
    flex: 1 1 65%;
    height: auto;
    margin-top: -30px;
}

main .banner .banner-text p {
    font-family: 'Karla', 'Arial', sans-serif;
    font-size: 1.2em;
    color: hsla(240, 3%, 30%, 1);
    font-weight: 400;
    line-height: 35px;
}

main .banner .banner-text p b {
    font-size: 1.3em;
    color: hsla(240, 3%, 95%, 1);
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 1.5px;
}

main .banner .banner-text img {
    width: 40%;
    margin-left: 5%;
}

main .banner .banner-text {
    flex: 0 0 50vw;
    height: 40vh;
    width: 50vw;
    position: relative;
    top: 0;
    left: 0;
}

/* HD Screens */

@media (max-width: 1920px) {
    main .banner .banner-text .banner-title {
        margin-left: 100px;
    }

    main .banner .banner-text h5 {
        margin-left: 100px;
    }

    main .banner .banner-text .banner-description {
        margin-left: 100px;
        width: calc(100% - 160px);
    }

    main .banner .banner-text .banner-title h1 {
        font-size: 5.2em;
    }
}

@media (max-width: 1680px) {
    main .banner .banner-text .banner-title {
        margin-left: 100px;
    }

    main .banner .banner-text h5 {
        margin-left: 100px;
        font-size: 1.4em;
    }

    main .banner .banner-text .banner-description {
        margin-left: 100px;
        width: calc(100% - 160px);
        height: 120px;
    }

    main .banner .banner-text .banner-title h1 {
        font-size: 4.2em;
    }
    main .banner .banner-text p {
        font-size: 1em;
    }

    main .banner .banner-text p b {
        font-size: 1.1em;
    }
}

/* HiDP Screens */

@media (max-width: 1280px) {
    main .banner .banner-text .banner-description {
    }

    main .banner .banner-text {
        flex: 0 0 90vw;
        width: 90vw;
    }
}
