header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    height: 60px;
    padding: 45px 50px;
    display: flex;
    transition: 0.275s;
}

header .Logo {
    flex: 0 0 300px;
    width: 300px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

header .Logo img {
    flex: 0 0 50px;
    width: 50px;
    height: auto;
}

header .Logo .Logo-Name {
    margin-left: 30px;
    flex: 1 1 200px;
    font-family: Karla, 'Arial', sans-serif;
    color: #fff;
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
    transition: 0.275s;
}

header.fixed .Logo .Logo-Name {
    font-size: 14px;
    line-height: 18px;
}

header .Logo .Logo-Name span {
    font-size: 15px;
    transition: 0.275s;
}

header.fixed .Logo .Logo-Name span {
    font-size: 12px;
}

header nav {
    flex: 0 0 600px;
    text-align: right;
    display: none;
}

header nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

header nav ul li {
    display: inline-block;
    padding: 0 2em;
    line-height: 60px;
    font-family: Karla, 'Arial', sans-serif;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    text-align: center;

    background: linear-gradient(#344cff, #344cff) left no-repeat, #fff;
    background-position: bottom left;
    background-size: 0% 100%;
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 0.8s;
}

header nav ul li i {
    background: linear-gradient(#344cff, #344cff) left no-repeat, #fff;
    background-position: bottom left;
    background-size: 0% 100%;
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 0.8s 0.2s;
}

header nav ul li:hover,
header nav ul li:hover i {
    background-size: 100% 100%;
    transform: scale(1.1);
}

header .middle-filler {
    flex: 1 1 auto;
}
