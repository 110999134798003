@import url(https://fonts.googleapis.com/css?family=Karla:400,700|Libre+Baskerville:400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
    width: 5px;
}

*::-webkit-scrollbar-track {
    background: rgba(55, 55, 55, 0.1);
}

*::-webkit-scrollbar-thumb {
    background-color: #e7e1d0;
    outline: 1px solid #e7e1d0;
}

html,
body {
    margin: 0;
    padding: 0;
    background-color: #171717;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.page-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: #171717;
    background: url(../../static/media/background.3c842266.png) center center no-repeat;
    background-size: cover;
    -webkit-filter: saturate(0);
            filter: saturate(0);
    z-index: 1;
}

.page-background.page-two {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 170vh;
    background-color: #e7e1d0;
    background: #e7e1d0;
    z-index: -1;
}

.page-background.page-three {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 120vh;
    background: #171717;
    -webkit-filter: none;
            filter: none;
    z-index: 1;
    box-shadow: 0 5px 25px #171717;
}

.page-background.page-three:before {
    content: '';
    position: absolute;
    top: -12vw;
    left: 0;
    width: 100vw;
    height: 12vw;
    right: 0;
    background: url(../../static/media/mountain-vector.b5711857.png);
    background-size: auto 100%;
}

.page-background.page-three .profile {
    position: absolute;
    top: 80px;
    left: 200px;
    bottom: 140px;
    height: calc(45vw - 300px);
    width: calc(45vw - 300px);
    border-radius: 50%;
    right: 65%;
    background: url(../../static/media/about.c586024b.png) center right no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 10px #000;
}

.page-background.footer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 120px;
    background-color: #e7e1d0;
    background: #e7e1d0;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 150px 0 0 #171717;
}

.page-background.footer .copyright {
    flex: 1 1 200px;
    line-height: 20px;
    margin: 0 0 0 200px;
    font-family: 'Karla', 'Arial', sans-serif;
    padding: 0;
    font-weight: bold;
    color: hsla(0, 0%, 10%, 0.9);
}

main {
    position: absolute;
    top: 0;
    left: 50px;
    right: 50px;
    height: 100vh;
    width: calc(100vw - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1280px) {
    .page-background.page-three .profile {
        position: absolute;
        top: 140px;
        left: 100px;
        bottom: auto;
        height: calc(45vw - 200px);
        width: calc(45vw - 200px);
        border-radius: 50%;
    }
}

.Sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 60px;
  z-index: 12;
}

.Sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
}

.Sidebar ul li {
  color: hsla(240,3%,30%,1);
  margin: 35px 0;
  padding: 0 35px;
  font-size: 20px;
}

.Sidebar ul li a {
  text-decoration: none;
  color: hsla(240,3%,30%,1);
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}

.Sidebar ul li a:hover {
  color: hsla(240,3%,60%,1);
}

header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    height: 60px;
    padding: 45px 50px;
    display: flex;
    -webkit-transition: 0.275s;
    transition: 0.275s;
}

header .Logo {
    flex: 0 0 300px;
    width: 300px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

header .Logo img {
    flex: 0 0 50px;
    width: 50px;
    height: auto;
}

header .Logo .Logo-Name {
    margin-left: 30px;
    flex: 1 1 200px;
    font-family: Karla, 'Arial', sans-serif;
    color: #fff;
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
    -webkit-transition: 0.275s;
    transition: 0.275s;
}

header.fixed .Logo .Logo-Name {
    font-size: 14px;
    line-height: 18px;
}

header .Logo .Logo-Name span {
    font-size: 15px;
    -webkit-transition: 0.275s;
    transition: 0.275s;
}

header.fixed .Logo .Logo-Name span {
    font-size: 12px;
}

header nav {
    flex: 0 0 600px;
    text-align: right;
    display: none;
}

header nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

header nav ul li {
    display: inline-block;
    padding: 0 2em;
    line-height: 60px;
    font-family: Karla, 'Arial', sans-serif;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    -webkit-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
    text-align: center;

    background: -webkit-gradient(linear, left top, left bottom, from(#344cff), to(#344cff)) left no-repeat, #fff;

    background: linear-gradient(#344cff, #344cff) left no-repeat, #fff;
    background-position: bottom left;
    background-size: 0% 100%;
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-transition: 0.8s;
    transition: 0.8s;
}

header nav ul li i {
    background: -webkit-gradient(linear, left top, left bottom, from(#344cff), to(#344cff)) left no-repeat, #fff;
    background: linear-gradient(#344cff, #344cff) left no-repeat, #fff;
    background-position: bottom left;
    background-size: 0% 100%;
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-transition: 0.8s 0.2s;
    transition: 0.8s 0.2s;
}

header nav ul li:hover,
header nav ul li:hover i {
    background-size: 100% 100%;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

header .middle-filler {
    flex: 1 1 auto;
}

main .banner .masonry-grid {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50vw - 170px);
    width: calc(50vw - 170px);
    height: 100vh;
    margin-left: 70px;
}

.masonry-grid.work {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50vw - 100px);
    width: calc(50vw - 100px);
    height: 50vh;
    margin-left: 200px;
}

.masonry-grid .masonry-wrapper {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    display: flex;
}

.masonry-grid .masonry-wrapper .masonry-column {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(50% - 100px);
    width: calc(50% - 100px);
    margin-right: 3vw;
    position: relative;
    top: 0;
    left: 0;
}

.masonry-grid .masonry-wrapper .masonry-column:last-child {
    margin-top: 120px;
}

.masonry-grid.work .masonry-wrapper .masonry-column:last-child {
    margin-top: 60px;
}

.masonry-grid .brick {
    width: calc(100% - 50px);
    height: auto;
    position: relative;
    top: 0;
    left: 0;
    cursor: pointer;
    margin-bottom: 90px;
}

.masonry-grid .brick .brick-image {
    width: calc(100% - 40px);
    margin-left: 40px;
    height: auto;
    border-radius: 12px;
    box-shadow: 6px 6px 10px hsla(240, 3%, 2%, 0.2), 0 0 20px hsla(240, 3%, 2%, 0.1);
    overflow: hidden;
    -webkit-transition: box-shadow 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    transition: box-shadow 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

.masonry-grid .brick .brick-image img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.masonry-grid .brick .brick-title {
    font-family: 'Karla', 'Arial', sans-serif;
    font-size: 14px;
    color: hsla(240, 3%, 30%, 1);
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 1px;
    width: 30px;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: absolute;
    top: 0;
    left: 0;
    text-align: right;
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
    -webkit-text-orientation: mixed;
            text-orientation: mixed;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transition: color 0.4s ease-in-out;
    transition: color 0.4s ease-in-out;
}

.masonry-grid .brick .brick-title i {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.masonry-grid .brick:hover .brick-image img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.masonry-grid .brick:hover .brick-title {
    color: #344cff;
}

.masonry-grid .brick:hover .brick-image {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    box-shadow: 6px 15px 20px hsla(240, 3%, 2%, 0.4);
}

@media (max-width: 2300px) {
    .masonry-grid .masonry-wrapper .masonry-column {
        margin-right: 1vw;
    }
}

/* HD */
@media (max-width: 1920px) {
    main .banner .masonry-grid {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(50vw - 120px);
        width: calc(50vw - 120px);
        height: 100vh;
        margin-left: 70px;
    }

    .masonry-grid.work {
        flex: 0 0 50vw;
        width: 50vw;
        height: 50vh;
        margin-left: 100px;
    }
}

@media (max-width: 1280px) {
    main .banner .masonry-grid {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(50vw - 100px);
        width: calc(50vw - 100px);
        height: 100vh;
        margin-left: -35vw;
        margin-top: 150vh;
    }
    .masonry-grid .brick .brick-title {
        font-size: 10px;
    }
}

/* Default (>1920px) */

.banner {
    flex: 1 1 auto;
    width: calc(100vw - 100px);
    height: 80vh;
    display: flex;
    align-items: center;
}

main .banner .banner-text h5 {
    font-family: 'Libre Baskerville', 'Arial', sans-serif;
    font-size: 1.75em;
    color: hsla(240, 3%, 65%, 1);
    font-weight: 400;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    text-overflow: hidden;
    margin-left: 200px;
    line-height: 20px;
}

main .banner .banner-text .banner-title {
    margin-left: 200px;
    white-space: normal;
    word-wrap: break-word;
}

main .banner .banner-text .banner-title h1 {
    display: inline-block;
    font-family: 'Libre Baskerville', 'Arial', sans-serif;
    font-size: 6.2em;
    color: hsla(240, 3%, 98%, 1);
    font-weight: 600;
    text-shadow: 4px 4px 15px rgba(0, 0, 0, 0.6);
    line-height: 110px;
    margin-top: -20px;
    letter-spacing: 1px;
    white-space: nowrap;
    word-wrap: break-word;
    position: relative;
    top: 0;
    left: 0;
    z-index: 11;
}

main .banner .banner-text .banner-description {
    margin-left: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 260px);
    margin-top: 0px;
    background: hsla(240, 3%, 12%, 0.75);
    border-radius: 16px;
    box-shadow: 6px 6px 10px hsla(240, 3%, 3%, 0.2), 0 0 20px hsla(240, 3%, 8%, 0.1);
    padding: 45px 30px 30px 18px;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    height: 160px;
}

main .banner .banner-text .banner-description .banner-icons {
    flex: 0 0 35%;
    height: auto;
}

main .banner .banner-text .banner-description .banner-description-text {
    flex: 1 1 65%;
    height: auto;
    margin-top: -30px;
}

main .banner .banner-text p {
    font-family: 'Karla', 'Arial', sans-serif;
    font-size: 1.2em;
    color: hsla(240, 3%, 30%, 1);
    font-weight: 400;
    line-height: 35px;
}

main .banner .banner-text p b {
    font-size: 1.3em;
    color: hsla(240, 3%, 95%, 1);
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 1.5px;
}

main .banner .banner-text img {
    width: 40%;
    margin-left: 5%;
}

main .banner .banner-text {
    flex: 0 0 50vw;
    height: 40vh;
    width: 50vw;
    position: relative;
    top: 0;
    left: 0;
}

/* HD Screens */

@media (max-width: 1920px) {
    main .banner .banner-text .banner-title {
        margin-left: 100px;
    }

    main .banner .banner-text h5 {
        margin-left: 100px;
    }

    main .banner .banner-text .banner-description {
        margin-left: 100px;
        width: calc(100% - 160px);
    }

    main .banner .banner-text .banner-title h1 {
        font-size: 5.2em;
    }
}

@media (max-width: 1680px) {
    main .banner .banner-text .banner-title {
        margin-left: 100px;
    }

    main .banner .banner-text h5 {
        margin-left: 100px;
        font-size: 1.4em;
    }

    main .banner .banner-text .banner-description {
        margin-left: 100px;
        width: calc(100% - 160px);
        height: 120px;
    }

    main .banner .banner-text .banner-title h1 {
        font-size: 4.2em;
    }
    main .banner .banner-text p {
        font-size: 1em;
    }

    main .banner .banner-text p b {
        font-size: 1.1em;
    }
}

/* HiDP Screens */

@media (max-width: 1280px) {
    main .banner .banner-text .banner-description {
    }

    main .banner .banner-text {
        flex: 0 0 90vw;
        width: 90vw;
    }
}

.work-title {
    margin: 0;
    padding: 0;
    z-index: -1;
    font-size: 110px;
}

.work-title h1 {
    font-family: 'Karla', 'Arial', sans-serif;
    padding: 0;
    font-weight: bold;
    margin: -50px 0 0 200px;
    width: 1050px;
    color: hsla(0, 0%, 10%, 0.9);
    position: relative;
    top: 0;
    left: 0;
}

.work-title h1:after {
    content: '';
    position: absolute;
    top: 58px;
    left: 0;
    width: 100%;
    right: 0;
    bottom: 50px;
    height: calc(100% - 114px);
    border-top: 1px solid hsla(0, 0%, 75%, 1);
    border-bottom: 1px solid hsla(0, 0%, 75%, 1);
    z-index: -1;
}

.work-title h1:before {
    content: '';
    position: absolute;
    top: 28px;
    left: 20px;
    width: calc(100% - 45px);
    right: 0;
    bottom: 20px;
    height: calc(100% - 54px);
    border-left: 1px solid hsla(0, 0%, 75%, 1);
    border-right: 1px solid hsla(0, 0%, 75%, 1);
    z-index: -1;
}

.work-title h5 {
    font-family: 'Libre Baskerville', 'Arial', sans-serif;
    padding: 0;
    font-size: 26px;
    font-weight: 400;
    margin: 0 0 0 220px;
    width: 40vw;
    line-height: 50px;
    color: hsla(0, 0%, 40%, 1);
}

@media (max-width: 2200px) {
    .work-title {
        font-size: 90px;
    }
    .work-title h1 {
        padding: 0;
        font-weight: bold;
        margin: -50px 0 0 200px;
        width: 865px;
    }
    .work-title h1:after {
        top: 48px;
        height: 115px;
    }
    .work-title h1:before {
        left: 15px;
    }
}

/* HD */

@media (max-width: 1920px) {
    .work-title h1 {
        margin: -50px 0 0 100px;
    }
    .work-title h5 {
        margin: 0 0 0 120px;
        font-size: 22px;
    }
}

@media (max-width: 1680px) {
    .work-title {
        font-size: 70px;
    }
    .work-title h1 {
        padding: 0;
        font-weight: bold;
        margin: -50px 0 0 100px;
        width: 685px;
    }
    .work-title h1:after {
        top: 37px;
        height: 90px;
    }
    .work-title h1:before {
        left: 12px;
    }
}

@media (max-width: 1280px) {
    .work-title {
        font-size: 50px;
    }
    .work-title h1 {
        padding: 0;
        font-weight: bold;
        margin: -50px 0 0 100px;
        width: 480px;
    }
    .work-title h1:after {
        top: 27px;
        height: 64px;
        width: 490px;
    }
    .work-title h1:before {
        left: 9px;
        top: 20px;
        height: 80px;
        width: 457px;
    }
    .work-title h5 {
        margin: 0 0 0 120px;
        font-size: 18px;
        line-height: 36px;
        width: 80vw;
    }
}

.about-title {
    font-size: 80px;
    margin: 110px 0 0 0;
    padding: 0;
    z-index: 1;
    padding-left: 48vw;
    width: 45vw;
}

.about-title h1 {
    font-family: 'Karla', 'Arial', sans-serif;
    padding: 0;
    font-weight: bold;
    color: hsla(240, 3%, 98%, 1);
    position: relative;
    top: 0;
    left: 0;
}

.about-title p {
    font-family: 'Libre Baskerville', 'Arial', sans-serif;
    padding: 0;
    margin: -50px 0 0 0;
    font-weight: 400;
    color: hsla(240, 3%, 78%, 1);
    position: relative;
    font-size: 22px;
    line-height: 50px;
    top: 0;
    left: 0;
}

.about-title ul {
    font-family: 'Libre Baskerville', 'Arial', sans-serif;
    padding: 0;
    font-weight: 400;
    color: hsla(240, 3%, 78%, 1);
    position: relative;
    font-size: 22px;
    line-height: 60px;
    top: 0;
    left: 0;
    list-style: none;
    margin: 60px 40px;
    padding: 0;
    width: 100%;
}

.about-title ul li {
    display: inline-block;
    width: 50%;
}

.about-title ul li i {
    color: #344cff;
    padding-right: 20px;
}

/* HD */

@media (max-width: 1920px) {
    .about-title {
        font-size: 60px;
    }
    .about-title p {
        font-size: 20px;
    }
    .about-title ul {
        font-size: 18px;
    }
}

@media (max-width: 1680px) {
    .about-title {
        font-size: 50px;
    }
    .about-title p {
        font-size: 16px;
        line-height: 36px;
    }
    .about-title ul {
        font-size: 16px;
        line-height: 45px;
    }
}

@media (max-width: 1280px) {
    .about-title {
        font-size: 30px;
        margin-top: 200px;
    }
    .about-title h1 {
        line-height: 110px;
    }
    .about-title p {
        font-size: 14px;
        line-height: 36px;
    }
    .about-title ul {
        font-size: 14px;
        line-height: 35px;
    }
}

.contact-wrapper {
    width: calc(100vw - 200px);
    height: 50vh;
    margin-left: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-wrapper .contact-details {
    flex: 0 0 auto;
    width: 50%;
}

.contact-wrapper .contact-details .location {
    font-family: 'Karla', 'Arial', sans-serif;
    padding: 0;
    font-weight: bold;
    color: hsla(240, 3%, 98%, 1);
    position: relative;
    font-size: 100px;
    top: 0;
    left: 0;
}

.contact-wrapper .contact-details .location span {
    color: hsla(240, 3%, 50%, 1);
    position: relative;
    font-size: 70px;
}

.contact-wrapper .contact-details .email {
    font-family: 'Karla', 'Arial', sans-serif;
    padding: 0;
    font-weight: bold;
    color: hsla(240, 3%, 98%, 1);
    margin-top: 100px;
    position: relative;
    font-size: 30px;
    top: 0;
    left: 0;
}

.contact-wrapper .contact-details .socials {
    font-family: 'Karla', 'Arial', sans-serif;
    padding: 0;
    font-weight: normal;
    color: hsla(240, 3%, 98%, 1);
    margin-top: 50px;
    position: relative;
    font-size: 30px;
    top: 0;
    left: 0;
}

.contact-wrapper .contact-details .socials span {
    font-size: 20px;
    text-align: center;
    font-family: 'Libre Baskerville', 'Arial', sans-serif;
    font-style: italic;
    color: hsla(240, 3%, 58%, 1);
}

.contact-wrapper .contact-map {
    flex: 1 1 50%;
    width: 50%;
    position: relative;
    top: 0;
    text-align: center;
    left: 0;
}

.contact-map img {
    height: 350px;
    -webkit-filter: saturate(0);
            filter: saturate(0);
    opacity: 0.1;
}

/* HD */
@media (max-width: 1920px) {
    .contact-wrapper {
        width: calc(100vw - 100px);
        margin-left: 100px;
    }
    .contact-wrapper .contact-details .location {
        font-size: 80px;
    }
    .contact-wrapper .contact-details .location span {
        font-size: 55px;
    }
    .contact-wrapper .contact-details .email {
        font-size: 26px;
    }
    .contact-wrapper .contact-details .socials {
        font-size: 26px;
    }

    .contact-map img {
        height: 290px;
    }
}

@media (max-width: 1280px) {
    .contact-wrapper {
        width: calc(100vw - 100px);
        margin-left: 100px;
        margin-top: 70px;
    }
    .contact-wrapper .contact-details .location {
        font-size: 60px;
    }
    .contact-wrapper .contact-details .location span {
        font-size: 45px;
    }
    .contact-wrapper .contact-details .email {
        font-size: 20px;
    }
    .contact-wrapper .contact-details .socials {
        font-size: 20px;
    }

    .contact-map img {
        height: 200px;
    }
}

