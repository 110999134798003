.about-title {
    font-size: 80px;
    margin: 110px 0 0 0;
    padding: 0;
    z-index: 1;
    padding-left: 48vw;
    width: 45vw;
}

.about-title h1 {
    font-family: 'Karla', 'Arial', sans-serif;
    padding: 0;
    font-weight: bold;
    color: hsla(240, 3%, 98%, 1);
    position: relative;
    top: 0;
    left: 0;
}

.about-title p {
    font-family: 'Libre Baskerville', 'Arial', sans-serif;
    padding: 0;
    margin: -50px 0 0 0;
    font-weight: 400;
    color: hsla(240, 3%, 78%, 1);
    position: relative;
    font-size: 22px;
    line-height: 50px;
    top: 0;
    left: 0;
}

.about-title ul {
    font-family: 'Libre Baskerville', 'Arial', sans-serif;
    padding: 0;
    font-weight: 400;
    color: hsla(240, 3%, 78%, 1);
    position: relative;
    font-size: 22px;
    line-height: 60px;
    top: 0;
    left: 0;
    list-style: none;
    margin: 60px 40px;
    padding: 0;
    width: 100%;
}

.about-title ul li {
    display: inline-block;
    width: 50%;
}

.about-title ul li i {
    color: #344cff;
    padding-right: 20px;
}

/* HD */

@media (max-width: 1920px) {
    .about-title {
        font-size: 60px;
    }
    .about-title p {
        font-size: 20px;
    }
    .about-title ul {
        font-size: 18px;
    }
}

@media (max-width: 1680px) {
    .about-title {
        font-size: 50px;
    }
    .about-title p {
        font-size: 16px;
        line-height: 36px;
    }
    .about-title ul {
        font-size: 16px;
        line-height: 45px;
    }
}

@media (max-width: 1280px) {
    .about-title {
        font-size: 30px;
        margin-top: 200px;
    }
    .about-title h1 {
        line-height: 110px;
    }
    .about-title p {
        font-size: 14px;
        line-height: 36px;
    }
    .about-title ul {
        font-size: 14px;
        line-height: 35px;
    }
}
